import axios from "axios";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useNotice } from "../context/NoticeContext";
import useAuthContext from "../hooks/useAuthContext";

axios.defaults.withCredentials = true;

const GitHubCallback = () => {
	const navigate = useNavigate();
	const { addNotice } = useNotice();
	const { dispatch } = useAuthContext();

	const handleGithubLogin = useCallback(async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");
		if (!code) return;

		try {
			const tokenResponse = await axios.post(
				process.env.REACT_APP_REDIRECT_URI,
				{ code },
				{ withCredentials: true },
			);

			const { accessToken, email } = tokenResponse.data;
			if (accessToken && email) {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/exchange-github-access-token`,
					{
						token: accessToken,
						email,
					},
				);

				if (response?.data?.userToken) {
					const token = response.data.userToken;
					const userExistsResponse = await axios.post(
						`${process.env.REACT_APP_API_URL}/checkuserexists`,
						{
							type: "github",
							token: token,
							email: response.data.email,
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
							withCredentials: true,
						},
					);

					if (userExistsResponse?.data?.exists) {
						const { sessionToken, refreshToken, email } =
							userExistsResponse.data;

						const userObject = {
							token: sessionToken,
							refreshToken,
							email,
							signupStatus: "active",
						};

						Cookies.set("sessionToken", sessionToken, {
							sameSite: "strict",
							secure: true,
						});
						Cookies.set("refreshToken", refreshToken, {
							sameSite: "strict",
							secure: true,
						});
						Cookies.set("user", JSON.stringify(userObject), {
							sameSite: "strict",
							secure: true,
						});

						localStorage.setItem("user", JSON.stringify(userObject));

						dispatch({
							type: "LOGIN",
							payload: userObject,
						});

						navigate("/home");
					}
				}
			}
		} catch (err) {
			addNotice({
				type: "error",
				message: "Could not authenticate GitHub user. Please try again.",
			});
			navigate("/login");
			console.error("Login failed:", err);
		}
	}, [navigate, dispatch, addNotice]);

	useEffect(() => {
		handleGithubLogin();
	}, [handleGithubLogin]);

	return <div>Processing GitHub login...</div>;
};

export default GitHubCallback;
