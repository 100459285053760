import { faCheck, faCodeSimple, faCrown, faHashtag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Question = ({ question, openQuestionModal, userData }) => {
  const isPremiumUser = userData.isPremium;
  const isQuestionPremium = question.premium;
  const { title, genre } = question;

  const showQuestionProgress = question.isCorrectlyAnswered;
  const showQuestionAccessibleStatus = !isPremiumUser && question.isCorrectlyAnswered === false && isQuestionPremium;

  return (
    <li
      className={`questionItem ${showQuestionProgress ? "questionItemAnswered" : ""}`}
      onClick={() => openQuestionModal(question)}
    >
      <div className={`questionInfo`}>
        <div className="questionGenre">
          {genre === "code" ? (
            <FontAwesomeIcon icon={faCodeSimple} className="icon" />
          ) : (
            <FontAwesomeIcon icon={faHashtag} className="icon" />
          )}
        </div>
        <div className="questionTitle">{title}</div>
      </div>
      {showQuestionAccessibleStatus && <FontAwesomeIcon icon={faCrown} className="icon premiumIcon" />}
      {showQuestionProgress && (
        <div className="questionStatus">
          <FontAwesomeIcon icon={faCheck} className="icon" />
        </div>
      )}
    </li>
  );
};

export default Question;
