import { useEffect } from "react";
import { useConsecutiveContext } from "../context/ConsecutiveContext";
import axiosInstance from "../utils/axiosConfig";

export const useStreakManager = () => {
  const { setCounter } = useConsecutiveContext();

  useEffect(() => {
    const checkAndUpdateStreak = async () => {
      try {
        const userString = localStorage.getItem("user");
        const userData = JSON.parse(userString);
        const token = userData.token;
        const response = await axiosInstance.get("/checkStreak", {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-timezone-offset": new Date().getTimezoneOffset().toString(),
          },
        });
        const newStreak = response.data.streakDays;
        setCounter(newStreak);
      } catch (error) {
        console.error("Failed to check streak:", error);
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized: User may need to log in again");
        }
      }
    };

    checkAndUpdateStreak();
  }, []);
};
