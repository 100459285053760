import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useModal } from "../context/ModalContext";
import { useNotice } from "../context/NoticeContext";
import axiosInstance from "../utils/axiosConfig";

const Checkout = ({ userData, token, setUserData, setModalContent, openSubscribeModal }) => {
  const [formattedDate, setFormattedDate] = useState(null);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const { addNotice } = useNotice();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    const dateString = userData.premiumUntil;
    const premiumDate = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = premiumDate.toLocaleDateString("en-US", options);
    setFormattedDate(formattedDate);
  }, [userData]);

  const handleUnsubscribe = useCallback(async () => {
    setUnsubscribing(true);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/unsubscribe`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      addNotice({
        type: "success",
        message: "Successfully unsubscribed",
      });

      setUserData({
        ...userData,
        subscribed: false,
        isPremium: data.isPremium,
        premiumUntil: data.nextBillingDate,
      });
    } catch (error) {
      console.error("Error unsubscribing:", error);
      addNotice({
        type: "error",
        message: error.response?.data?.message || "Failed to unsubscribe. Please try again.",
      });
    } finally {
      setUnsubscribing(false);
      closeModal();
      setModalContent({ isOpen: false });
    }
  }, [token, userData, setUserData, addNotice, closeModal, setModalContent]);

  const confirmUnsubscribe = useCallback(() => {
    openModal("unsubscribe", {
      title: "Confirm Unsubscribe",
      content: (
        <>
          <div className="modal-body-row">
            <p>
              Are you sure you want to unsubscribe from the premium plan? This action will take effect at the end of your
              current billing cycle.
            </p>
          </div>
          <div className="modal-body-row modal-body-row-buttons">
            <button className="button-no-border" onClick={closeModal}>
              Cancel
            </button>
            <button
              data-testid="confirmUnsubscribe"
              className={`button-danger-fill ${unsubscribing ? "button-disabled" : ""}`}
              onClick={handleUnsubscribe}
              disabled={unsubscribing}
            >
              Unsubscribe
              {unsubscribing && <FontAwesomeIcon icon={faCircleNotch} spin />}
            </button>
          </div>
        </>
      ),
    });
  }, [openModal, closeModal, unsubscribing, handleUnsubscribe]);

  if (!userData.isPremium) {
    return (
      <div className="modal-body-row">
        <p>You're on the free plan</p>
        <div>
          <button onClick={openSubscribeModal}>Subscribe</button>
        </div>
      </div>
    );
  }

  if (userData.isPremium && userData.subscribed) {
    return (
      <div className="modal-body-row">
        <p>
          You're on the premium plan.
          {formattedDate && <> It will renew on {formattedDate}.</>}
        </p>
        <button onClick={confirmUnsubscribe}>Unsubscribe</button>
      </div>
    );
  }

  if (userData.isPremium && !userData.subscribed) {
    return (
      <div className="modal-body-row">
        <p>
          You're on the premium plan.
          {formattedDate && <> Your premium access will end on {formattedDate}.</>}
        </p>
        <button onClick={openSubscribeModal}>Resubscribe</button>
      </div>
    );
  }

  return null;
};

export default Checkout;
