import Cookies from "js-cookie";
import React from "react";
import github from "../assets/images/github.png";
import { useNotice } from "../context/NoticeContext";

const GitHubLogin = () => {
  const { addNotice } = useNotice();

  const handleGithubSubmit = () => {
    try {
      window.location.href = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_BASE_URL}/github/callback
      `;
    } catch (err) {
      console.error("Login failed:", err);
      addNotice({
        type: "error",
        message: "Login failed. Please try again.",
      });
    }
  };

  return (
    <button data-testid="githubLogin" onClick={handleGithubSubmit} className="button-large">
      <img src={github} alt="GitHub Logo" />
      Continue with GitHub
    </button>
  );
};

const removeCookies = () => {
  Cookies.remove("githubUser");
  Cookies.remove("github-primary-email");
  Cookies.remove("access_token");
};

export const githubLogout = ({ setProfile, setGithubUser, dispatch }) => {
  setProfile(null);
  setGithubUser(null);
  removeCookies();
  dispatch({ type: "LOGOUT" });
};

export default GitHubLogin;
